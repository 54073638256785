import logoImage from './collections_CK3ZueicxPvf9CwxoyQB_icon_A6j8wuUsTCdcevV7mZK6_Across-logo-greenbg (1).webp';
import cloudlinesOne from './cloud-staking-728bb740.svg';
import cloudlinesTwo from './op-cloud-rebate-f23b7655.svg';
import optimismLogo from './optimism-ethereum-op-logo.png';
import eth from "./ethereum-logo-4706363e.svg";
import usd from "./usd-coin-usdc-logo.png";
import usdt from "./tether-usdt-logo.png";
import dai from "./multi-collateral-dai-dai-logo.png";
import wbtc from "./wrapped-bitcoin-wbtc-logo.png"; 
import spinner_ from "./ZKZg.gif"; 
import snx from "./synthetix-symbol-4325380-3591017.png";
import arbitrum from "./arbitrum.png";
import base from "./base.png";
import polygon from "./polygon.webp";
import ZkSync from "./zkSync.png";
import coinbase from "./coinbase.svg";
import walletconnect from "./WalletConnect.png";
import coinbaseApp from "./coinbaseApp.svg";
import qrCode from "./qrCode.svg";
import qrCode2 from "./qrcode2.svg";
import ledger from "./Screenshot__122_-removebg-preview.png";
import metaMask from "./MetaMask.png";
import block from "./Screenshot__126_-removebg-preview.png"

export const logo = logoImage;
export const newLogo = "https://i.pinimg.com/originals/d3/83/7b/d3837b6973ed8a77319e1656994734a1.png";
// export const newLogo = "https://upload.wikimedia.org/wikipedia/commons/thumb/0/05/Ethereum_logo_2014.svg/384px-Ethereum_logo_2014.svg.png?20161015085252";
// export const newLogo = "https://cryptologos.cc/logos/injective-inj-logo.png";
export const backgroundLinesOne = cloudlinesOne;
export const backgroundLinesTwo = cloudlinesTwo;
export const optimisonLogo = optimismLogo;
export const ethLogo = eth;
export const usdtLogo = usdt;
export const usdLogo = usd;
export const daiLogo = dai;
export const wbtcLogo = wbtc;
export const spinner = spinner_;
export const snxLogo = snx;
export const arbitrumLogo = arbitrum;
export const baseLogo = base;
export const polygonLogo = polygon;
export const ZkSyncLogo = ZkSync;
export const coinbaseLogo = coinbase;
export const coinbaseAppLogo = coinbaseApp;
export const walletConnecLogo = walletconnect;
export const qrCodeTemplate = qrCode;
export const qrCodeTemplate2 = qrCode2;
export const ledgerIcon = ledger;
export const metaMaskLogo = metaMask;
export const blockLogo = block;
